import React from "react";
import workImage1 from "../image/logo1.png";
import workImage2 from "../image/NT.png";
import workImage3 from "../image/logo2.jpg";
import workImage4 from "../image/logo3.png";

import "./Experience.css";

export const Experience = () => {
  const experiences = [
    {
      company: "A Round Entertainment",
      title: "Software Engineer Intern",
      location: "San Jose, CA, US",
      time: "Sept, 2024 - Present",
      description:
        "Implemented features of a subscription-based mobile application using Node.js, Express.js, and Firebase, delivering a seamless cross-platform experience. \nDeveloped and deployed 15+ RESTful backend APIs and data models supporting functionalities such as user profile management, matching algorithms, Stripe payment integration, secure user authentication (JWT/OAuth), and referral systems. \nEnhanced system performance by integrating Redis as a caching layer, improving data retrieval speed by 40% and significantly reducing database load. Leveraged Google Cloud Storage to manage and store large media files, ensuring scalability and reliability. \nImplemented Single Sign-On (SSO) authentication and phone/email verification using Firebase Authentication, improving security and streamlining the onboarding process. \nCollaborated with the UI/UX team to implement 5+ front-end features, including user preference customization, membership subscription flow, contributing to a 25% increase in user retention. · Led project management efforts by tracking progress using Notion, working closely with the CTO to track deliverables, prioritize tasks, and ensure on-time project execution.",
      image: workImage1,
    },
    {
      company: "Network Tree",
      title: "Software Engineer Intern",
      location: "New York, NY, US",
      time: "May, 2024 - Sept, 2024",
      description:
        "Implemented cross-platform front-end features using React Native, ensuring a consistent and responsive user experience on both iOS and Android devices. · Developed backend APIs using Node.js and Express.js, integrating Auth0 for JWT-based authentication with express-oauth2-jwt-bearer to enforce role-based access control (RBAC). \nIntegrated Redux for managing asynchronous actions, optimizing data flow and improving app performance with efficient state transitions using Redux Thunk for handling side-effects. \nConfigured SSL/TLS protocols for secure HTTP connections. Integrated Stripe for payment processing, and Google OAuth 2.0 for SSO authentication, ensuring secure transactions and streamlined user login. \nUsed express-session for session management to streamline logins and enhance user convenience. \nLeveraged AWS EventBridge Scheduler for scheduled backend jobs, while deploying microservices to Amazon ECS for enhanced scalability, ensuring seamless handling of increasing user traffic",
      image: workImage2,
    },
  ];

  return (
    <section id="work-experience">
      <h3>EXPERIENCE</h3>

      <div className="experience-container">
        {experiences.map((experience, index) => (
          <div key={index} className="experience-item">
            <img
              src={experience.image}
              alt={experience.company}
              className="experience-image"
            />
            <div className="experience-text">
              <h2>{experience.company}</h2>
              <h4>{experience.title}</h4>
              <h5>{experience.location}</h5>
              <h5>{experience.time}</h5>
              <p>{experience.description}</p>
              {experience.skills && (
                <>
                  <p>Skills: {experience.skills.join(", ")}</p>
                </>
              )}
            </div>
          </div>
        ))}
      </div>
    </section>
  );
};
